































































import { UtilsString } from "@/utils/utils-string";
import { Component, Prop, Vue } from "vue-property-decorator";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { pantalla_modo_lista } from "@/shared/dtos/dynamic_tables/pantalla_modo_lista";
import FullDynamicTable from "@/views/dynamic_tables/full_dynamic_table.vue";
import ViewKanban from "@/views/dynamickanban/ViewKanban.vue";
@Component({
  components: {
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class Dynamic_change_views extends Vue {
  @Prop({ default: 0 }) id_tabla!: number;
  public componente_activo: any = null;
  public componentes: any[] = [];
  public search: string = "";

  public pantalla_modo_lista: pantalla_modo_lista = new pantalla_modo_lista();

  created() {
    this.componentes.push({
      nombre: "Lista",
      icono: "view_list",
      component: FullDynamicTable,
    });
    this.componentes.push({
      nombre: "Kanban",
      icono: "view_week",
      component: ViewKanban,
    });
    this.componente_activo = this.componentes[0];

    pantalla_modo_listaModule
      .getpantalla_modo_lista(this.get_id_tabla)
      .then((x: pantalla_modo_lista) => (this.pantalla_modo_lista = x));
  }

  get get_id_tabla() {
    let id = this.$route.meta.id;
    if (id === undefined) {
      id = this.$route.params.id;
    }
    if (this.id_tabla > 0) {
      id = this.id_tabla;
    }
    return id;
  }

  get permission_new() {
    if (
      this.pantalla_modo_lista.pantalla === null ||
      this.pantalla_modo_lista.pantalla === undefined
    ) {
      return [];
    }
    if (
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_new) ===
      ""
    ) {
      return [];
    }
    return [
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_new),
    ];
  }

  get permission_update() {
    if (
      this.pantalla_modo_lista.pantalla === null ||
      this.pantalla_modo_lista.pantalla === undefined
    ) {
      return [];
    }
    if (
      UtilsString.ValueOf(
        this.pantalla_modo_lista.pantalla.permission_update
      ) === ""
    ) {
      return [];
    }
    return [
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_update),
    ];
  }

  get permission_delete() {
    if (
      this.pantalla_modo_lista.pantalla === null ||
      this.pantalla_modo_lista.pantalla === undefined
    ) {
      return [];
    }
    if (
      UtilsString.ValueOf(
        this.pantalla_modo_lista.pantalla.permission_delete
      ) === ""
    ) {
      return [];
    }
    return [
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_delete),
    ];
  }
}
